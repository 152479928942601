export default {
  breakpoints: ["40em", "52em", "65em"], //["26em", "68em", "125em"], // mobile -> tablet, tablet -> desktop, desktop -> widescreen
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      'Helvetica, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "Helvetica, inherit",
    monospace: "Helvetica, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 38, 44, 50, 58, 66, 72, 88],
  sizes: [
    "1rem",
    "2rem",
    "3rem",
    "4rem",
    "5rem",
    "6rem",
    "7rem",
    "8rem",
    "16rem",
    "32rem",
    "0.2rem",
    "40rem",
    "12rem",
    "0.5rem",
    "1.2rem"
  ],
  fontWeights: {
    body: 400,
    heading: 800,
    lightbold: 600,
    bold: 800,
  },
  lineHeights: {
    body: 1.5,
    heading: 1,
  },
  colors: {
    text: "#000000",
    background: "#ffffff",
    primary: "#7CB951",
    secondary: "#74B064",
    tertiary: "#6BA679",
    quartary: "#558DAB",
    muted: "#808080",
    mutedTwo: "lightgrey",
    white: "#ffffff",
  },
  // variants can use custom, user-defined names:
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "lightbold",
    },
    body: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
  },
  spacing: {
    mb: [5, 6, 6],
  },
  navlink: {
    color: "quartary",
    textDecoration: "none",
    fontSize: 3,
    borderTop: "2px",
    borderBottom: "2px",
    borderRight: "0.5px",
    borderLeft: "0.5px",
    borderStyle: "solid",
    borderColor: "muted",
    py: 2,
    textAlign: "center",
    width: "100%",
    "&:first-of-type": {
      borderLeft: "2px",
      borderStyle: "solid",
      borderColor: "muted",
    },
    "&:last-of-type": {
      borderRight: "2px",
      borderStyle: "solid",
      borderColor: "muted",
    },
    "&:hover": {
      color: "primary",
      cursor: "pointer",
      borderColor: "primary",
    },
  },
  // variants for buttons
  buttons: {
    primary: {
      // you can reference other values defined in the theme
      color: "background",
      bg: "primary",
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  //Example styles
  styles: {
    root: {
      "@font-face": {
        fontDisplay: "block",
      },
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      variant: "text.heading",
      fontSize: 5,
    },
    h2: {
      variant: "text.heading",
      fontSize: 4,
    },
    h3: {
      variant: "text.heading",
      fontSize: 3,
    },
    h4: {
      variant: "text.heading",
      fontSize: 2,
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
};
